import { Component, Ref, Vue } from "vue-property-decorator";

@Component
export default class RequiredRuleMixin extends Vue {
  @Ref("form") readonly form!: any;

  public requiredRule = (val: string): boolean | string =>
    !!val || "Это поле обязательно для заполнения";

  public getMaxLengthRule = (maxLength: number) => {
    return (value: string) => {
      console.log(value, value.length, maxLength);
      return (
        value.length < maxLength ||
        `Количество символов не должно превышать ${maxLength}`
      );
    };
  };

  public validateForm(): boolean {
    if (!this.form) {
      return true;
    }

    return this.form.validate();
  }
}
