
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import UploadFileMixin from "@/mixins/upload-file.mixin";
import { baseMediaUrl } from "@/api/media";

@Component({ components: {} })
export default class ImageLoaderWithPreview extends mixins(UploadFileMixin) {
  private showImageLoader = false;

  private requiredPhotoRule(val: string): boolean | string {
    return !!val || !!this.src || "Это поле обязательно для заполнения";
  }

  handleLoadedFile(src: string): void {
    this.$emit("input", src);
  }

  @Prop() private readonly value!: string;

  @Watch("value", { immediate: true })
  private onSavedImageSrc(): void {
    this.src = this.value ? baseMediaUrl + this.value : '';
  }
}
