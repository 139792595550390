import { Component, Vue, Watch } from "vue-property-decorator";
import { uploadFile } from "@/api/api";
import { uniqueId } from "lodash";

@Component
export default class UploadFileMixin extends Vue {
  showFileLoader = false;
  file: null | File = null;
  src = "";
  fileLoaderError = "";
  progress = 0;

  // eslint-disable-next-line
    handleLoadedFile(src: string) {
    }

  @Watch("file")
  async updateFile(file: File): Promise<void> {
    if (!file) {
      return;
    }

    this.showFileLoader = true;
    const uuid = uniqueId();

    try {
      await this.$store.dispatch("fileLoadModule/addFile", uuid);
      this.src = await uploadFile(file, (progress) => {
        this.progress = progress
      });

      this.fileLoaderError = "";
      this.handleLoadedFile(this.src);
    } catch (e) {
      this.fileLoaderError = "Что-то пошло не так, попробуйте еще раз";
      console.error(e);
    } finally {
      await this.$store.dispatch("fileLoadModule/removeFile", uuid);
      this.showFileLoader = false;
    }
  }
}
